<template>
  <div class="uk-padding-small">
    <div
      class="tf-terms-text uk-margin-large-bottom uk-width-2-3@m uk-width-1-3@xl uk-width-1-2@l uk-align-center"
    >
      <h1 class="uk-text-lighter">Personvernerklæring</h1>

      <h4>Personvern, nettbasert behandling av personopplysninger</h4>
      <p>
        Denne personvernerklæringen gjelder for Veiledertjenesten.no sin
        nettside levert av SanaVia as (org.nr. 915948510), som er
        behandlingsansvarlig for de personopplysninger som samles inn på nevnte
        nettside. Databehandler er Telefactory AS.
      </p>

      <h4>Personopplysninger som behandles</h4>
      <ul>
        <li>Telefonnummer</li>
        <li>Kortnummer</li>
        <li>Navn/E-post ved opprettelse av brukerkonto (Min side)</li>
      </ul>

      <h4>Hvordan innhentes informasjonen?</h4>
      <p>
        SanaVia samler inn opplysninger gjennom bruk av nettsiden
        veiledertjenesten.no, herunder felt for registrering av telefon nummer,
        kortbetalingsløsning for Visa og Mastercard samt kontaktskjemaer.
      </p>

      <h4>Formålet med informasjonen som innhentes</h4>
      <p>
        Vi er avhengige av denne informasjonen for å kunne gi tilgang til
        produkter og tjenester, samt kunne behandle og besvare
        kundehenvendelser.
      </p>
      <p>
        Vi oppretter profiler/min side kontoer på bakgrunnen av informasjonen
        som innhentes, for å kunne tilby deg de mest relevante produktene og yte
        god kundeservice. Profilene baseres kun på informasjon som du frivillig
        gir oss, vi henter ikke inn informasjon for dette formålet fra
        tredjeparter.
      </p>

      <h4>Hvordan lagres informasjonen?</h4>
      <p>
        Informasjonen lagres via veiledertjenesten.no og egne systemer på
        nettsiden, inkludert hos databehandler.
      </p>

      <h4>Hvordan slettes opplysningene?</h4>
      <p>
        Informasjon lagret om deg kan slettes på oppfordring ved å ta kontakt
        med kundesupport på kundesupport@veiledertjenesten.no
      </p>

      <h4>Utlevering av informasjon til tredjepart?</h4>
      <p>
        Vi deler noen ganger med tredjeparter, men kun de opplysningene som er
        nødvendig for tredjeparten å oppfylle sin oppgave
      </p>
      <p>
        Hva vi deler og hvordan det skal behandles, er regulert i
        databehandleravtale med tredjeparten.
      </p>

      <h4>Rettigheter</h4>
      <p>
        Som bruker av nettsidene våre har du rett til å kreve innsyn i
        personopplysningene som vi behandler om deg og hvordan de behandles. Du
        kan også kreve retting, sletting og begrensninger i behandlingen av
        personopplysninger i henhold til personopplysningsloven.
      </p>
      <p>
        Hvor behandlingen av personopplysninger er basert på samtykke, kan du
        til enhver tid trekke samtykket tilbake.
      </p>
      <p>
        Dersom du mener at SanaVia AS ikke har overholdt dine rettigheter i
        henhold til personopplysningsloven, har du rett til å klage til den
        aktuelle tilsynsmyndigheten.
      </p>

      <h4>Kontaktinformasjon</h4>
      Telefon 22004600<br />
      {{ support_email }}<br />
      SanaVia AS, Hoffsveien 1A, 0275 Oslo.
    </div>
  </div>
</template>
<script>
import { settings } from "@/settings";

export default {
  data() {
    return {};
  },
  created: function() {},
  name: "privacy",
  methods: {},
  computed: {
    support_email() {
      return settings.SUPPORT_EMAIL;
    }
  }
};
</script>
