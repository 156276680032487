import axios from "axios";
import { settings } from "@/settings";

const API_SERVER = process.env.VUE_APP_API_SERVER;
const BILLING_API_SERVER = settings.BILLING_API_SERVER;

export const api_routes = {
  user: {
    login: API_SERVER + "sanctum/token",
    logout: API_SERVER + "logout",
    signup: API_SERVER + "register",
    me: API_SERVER + "me",
    sendverification: API_SERVER + "send-verification",
    verify: API_SERVER + "verify-user",
    namelookup: API_SERVER + "lookup",
    getnamebynumber: API_SERVER + "get-name",
    forgotpassword: API_SERVER + "auth/forgot",
    passwordreset: API_SERVER + "auth/reset",
    completequickpay: API_SERVER + "complete-quickpay",
    transactioncredits: API_SERVER + "transaction_credits",
    transactiondebits: API_SERVER + "transaction_debits",
    sendveilederemail: API_SERVER + "sendveilederemail",
    getcookie: API_SERVER + "sanctum/csrf-cookie",
    registeremail: API_SERVER + "register-email",
    registerinvoice: API_SERVER + "register-invoice"

  },
  bambora: {
    pay: API_SERVER + "bambora",
    quickpay: API_SERVER + "bambora-quick"
  },
  billing: {
    getprices: BILLING_API_SERVER + "payment_types"
  }
};

export const apiCall = ({ url, method, ...args }) =>
  new Promise((resolve, reject) => {
    let token = localStorage.getItem("user-token") || "";

    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;


    try {
      axios({
        method: method || "get",
        url: url,
        ...args
      })
        .then(resp => {
          resolve(resp.data);
        })
        .catch(error => {
          reject(error);
        });
    } catch (err) {
      reject(new Error(err));
    }
  });
