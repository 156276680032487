<template>
  <div
    ref="localTop"
    class="
      uk-align-center
      uk-width-2-5@l
      uk-width-1-2@m
      uk-width-2-3@s
      uk-card
      uk-card-body
      uk-card-default
      uk-border-rounded
    "
  >
    <div class="uk-margin">
      <h1 class="uk-text-center uk-text-lighter">Registrere faktura-konto</h1>
      <br />

      <div class="tf-label-text" for="text">Nummer (kan ikke endres)</div>
      <input
        class="uk-input uk-border-rounded"
        type="text"
        v-model="formatted_number"
        id="number"
        readonly
        disabled
      />
    </div>

    <div class="tf-margin-large">
      <div class="tf-label-text" for="text">Fullt navn</div>
      <input class="uk-input uk-border-rounded" type="email" v-model="name" />
    </div>

    <div class="tf-margin-large">
      <div class="tf-label-text" for="text">Gateaddresse</div>
      <input
        class="uk-input uk-border-rounded"
        type="text"
        v-model="street_address"
      />
    </div>

    <div class="tf-margin-large">
      <div class="tf-label-text" for="text">Postnummer</div>
      <input
        class="uk-input uk-border-rounded"
        type="text"
        v-model="post_code"
      />
    </div>

    <div class="tf-margin-large">
      <div class="tf-label-text" for="text">Poststed</div>
      <input class="uk-input uk-border-rounded" type="text" v-model="city" />
    </div>

    <div class="tf-margin-large">
      <div class="tf-label-text" for="text">Faktura på epost</div>
      <input
        class="uk-input uk-border-rounded"
        type="email"
        v-model="email"
        id="email"
      />
    </div>

    <br />
    <div class="uk-margin">
      <button
        class="uk-align-center uk-button uk-button-primary uk-border-pill"
        @click="completeRegistration()"
      >
        Fullfør registrering
        <span
          class="tf-button-chevron uk-float-right"
          uk-icon="icon: chevron-right; ratio:1.3"
        />
      </button>
    </div>
    <br />
  </div>
</template>
<script>
import { settings } from "@/settings";
import { mapGetters } from "vuex";
import PhoneNumber from "awesome-phonenumber";
export default {
  name: "invoice-registration-form",
  props: ["number"],
  data() {
    return {
      email: "",
      name: "",
      street_address: "",
      post_code: "",
      city: "",
      country: "",
    };
  },
  methods: {
    completeRegistration() {
      if (!this.validEmail(this.email)) {
        this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
          message: "Gyldig e-post addresse kreves.",
        });
        return;
      }
      this.$store
        .dispatch("auth/AUTH_INVOICE_REGISTRATION", {
          main_number: this.number,
          email: this.email,
          name: this.name,
          street_address: this.street_address,
          post_code: this.post_code,
          city: this.city,
          code: this.getVerificationCode,
          common_id: settings.COMMON_ID,
        })
        .then(() => {
          this.$emit("register:user", true);
          this.$store.dispatch("user/USER_DISPLAY_RIBBON", {
            message: "Faktura-kontoen er nå opprettet",
          });
        })
        .catch(() => {
          this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
            message:
              "Noe gikk galt under fullføring av registreringen, venligst ta kontakt med oss på " +
              settings.SUPPORT_EMAIL,
          });
          this.$emit("register:user", false);
        });
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  computed: {
    ...mapGetters("user", ["getVerificationCode"]),
    formatted_number() {
      return new PhoneNumber(this.number).getNumber("significant");
    },
  },
  mounted() {
    this.$scrollTo(this.$refs["localTop"], 500, {
      offset: -200,
      x: false,
      y: true,
    });
  },
};
</script>

<style scoped></style>
