<template>
  <div
    ref="localTop"
    class="uk-align-center uk-width-2-5@l uk-width-1-2@m uk-width-2-3@s uk-card uk-card-body uk-card-default uk-border-rounded"
  >
    <div class="uk-margin">
      <h1 class="uk-text-center uk-text-lighter">Registrer deg</h1>
      <br />

      <div class="tf-label-text" for="text">Nummer (kan ikke endres)</div>
      <input
        class="uk-input uk-border-rounded"
        type="text"
        v-model="formatted_number"
        id="number"
        readonly
        disabled
      />
    </div>

    <div class="tf-margin-large">
      <div class="tf-label-text" for="text">Fullt navn</div>
      <input
        class="uk-input uk-border-rounded"
        type="text"
        v-model="name"
        id="name"
      />
    </div>

    <div class="tf-margin-large">
      <div class="tf-label-text" for="text">Epost addresse</div>
      <input
        class="uk-input uk-border-rounded"
        type="email"
        v-model="email"
        id="email"
      />
    </div>

    <div class="tf-margin-large">
      <div class="tf-label-text" for="text">Passord</div>
      <input
        class="uk-input uk-border-rounded"
        type="password"
        v-model="password"
        id="password"
      />
    </div>

    <div>
      <div class="tf-label-text" for="text">Bekreft passord</div>
      <input
        class="uk-input uk-border-rounded"
        type="password"
        v-model="c_password"
        id="c_password"
      />
    </div>
    <br />
    <div class="uk-margin">
      <button
        class="uk-align-center uk-button uk-button-primary uk-border-pill"
        @click="completeRegistration()"
      >
        Fullfør registrering
        <span
          class="tf-button-chevron uk-float-right"
          uk-icon="icon: chevron-right; ratio:1.3"
        />
      </button>
    </div>
    <br />
  </div>
</template>
<script>
import { settings } from "@/settings";
import { mapGetters } from "vuex";
import PhoneNumber from "awesome-phonenumber";
export default {
  name: "registration-form",
  props: ["number"],
  data() {
    return {
      name: "",
      email: "",
      password: "",
      c_password: ""
    };
  },
  methods: {
    completeRegistration() {
      if (!this.validEmail(this.email)) {
        this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
          message: "Gyldig e-post addresse kreves."
        });
        return;
      }
      if (this.password.length < 8) {
        this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
          message: "Passord må inneholde minst 8 tegn"
        });
        return;
      }
      this.$store
        .dispatch("auth/AUTH_COMPLETE_QUICKPAY_REGISTRATION", {
          name: this.name,
          main_number: this.number,
          email: this.email,
          password: this.password,
          confirm_password: this.c_password,
          code: this.getVerificationCode,
          common_id: settings.COMMON_ID,
        })
        .then(() => {
          this.$emit("register:user", true);
        })
        .catch(error => {
          this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
            message:
              "Noe gikk galt under fullføring av registreringen, venligst ta kontakt med oss på " +
              settings.SUPPORT_EMAIL
          });
          this.$emit("register:user", false);
        });
    },
    nameLookup() {
      this.$store
        .dispatch("user/USER_NAME_LOOKUP", Number(this.number))
        .then(() => {
          this.name = this.getNameLookup.contacts
            ? this.getNameLookup.contacts[0].name
            : "";
        });
    },
    validEmail: function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
  computed: {
    ...mapGetters(["getNameLookup"]),
    ...mapGetters("user", ["getVerificationCode"]),
    formatted_number() {
      return new PhoneNumber(this.number).getNumber("significant");
    }
  },
  mounted() {
    this.$scrollTo(this.$refs["localTop"], 500, {
      offset: -200,
      x: false,
      y: true
    });
    //this.nameLookup();
  }
};
</script>

<style scoped></style>
