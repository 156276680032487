import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import Login from "@/views/auth/login";
import ForgotPassword from "@/views/auth/forgot-password";
import ResetPasswordForm from "@/views/auth/reset-password-form";
import App from "@/views/layouts/App";
import Home from "@/views/home";
import QuickPay from "@/views/quickpay";
import Profile from "@/views/profile";
import Terms from "@/views/terms";
import Privacy from "@/views/privacy";
import Contact from "@/views/contact";
import About from "@/views/about";
import Agent from "@/views/agent";
import RegisterEmail from "@/views/register-email";
import RegisterInvoice from "@/views/register-invoice";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next("/login");
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: App,
      children: [
        {
          path: "/login",
          name: "login",
          component: Login,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: "/profile",
          name: "profile",
          component: Profile,
          beforeEnter: ifAuthenticated
        },
        {
          path: "/",
          name: "home",
          component: Home
        },
        {
          path: "/quickpay",
          name: "quickpay",
          component: QuickPay
        },
        {
          path: "/vilkar",
          name: "terms",
          component: Terms
        },
        {
          path: "/personvern",
          name: "privacy",
          component: Privacy
        },
        {
          path: "/om-oss",
          name: "about",
          component: About
        },
        {
          path: "/agent",
          name: "agent",
          component: Agent
        },
        {
          path: "/kontakt-oss",
          name: "contact",
          component: Contact
        },
        {
          path: "/reset-password",
          name: "reset-password",
          component: ForgotPassword,
          meta: {
            auth: false
          }
        },
        {
          path: "/reset-password/:token/:email",
          name: "reset-password-form",
          component: ResetPasswordForm,
          meta: {
            auth: false
          }
        },
        {
          path: "/registrere-epost/:number?",
          name: "register-email",
          component: RegisterEmail,
          meta: {
            auth: false
          }
        },
/*         {
          path: "/registrere-faktura/:number?",
          name: "register-invoice",
          component: RegisterInvoice,
          meta: {
            auth: false
          }
        } */
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;

router.beforeEach((to, from, next) => {
  store.dispatch("user/USER_RESET_RIBBONS");
  next();
});
