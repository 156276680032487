<template>
  <div class="uk-padding-small">
    <div
      class="tf-terms-text uk-margin-large-bottom uk-width-2-3@m uk-width-1-3@xl uk-width-1-2@l uk-align-center"
    >
      <h1 class="uk-text-lighter">Kjøpsvilkår for Veiledertjenesten.no</h1>
      <h4>Generelle vilkår:</h4>

      <p><b>Selger er:</b> Veiledertjenesten.no (SanaVia AS).</p>
      <p>
        <b>Kjøper er:</b> Den person som er oppgitt som kjøper i bestillingen,
        og blir i det følgende benevnt du, deg (og tilsvarende benyttes
        betegnelser som din eller ditt).
      </p>
      <p>
        <b>Veiledertjenesten.no:</b> Selve nettbutikken/plattformen som er
        knyttet til domenet veiledertjenesten.no
      </p>
      <p>
        Salgsbetingelsene vil sammen med din bestilling utgjøre et samlet
        avtalegrunnlag for kjøpet.
      </p>
      <p>
        Det er en 18 års aldersgrense på kjøp av varer og tjenester via
        veiledertjenesten.no
      </p>

      <h4>1. Priser</h4>
      <p>Alle priser er inkludert merverdiavgift</p>
      <p>
        Veiledertjenesten.no er bundet av de priser som er opplyst til deg på
        kjøpstidspunktet via kjøpssiden til veiledertjenesten.no eller via
        informasjon som avspilles på betalingsnummeret.
      </p>

      <b>Kortbetaling</b>
      <p>
        Når du betaler med kort her på veiledertjenesten.no, og ringer veileder
        på annonserte 2105xxxx nummer koster tjenesten 29,90 per minutt.
      </p>

      <b>Faktura</b>
      <p>
        Når du ringer veileder på annonserte 829xxxxx nummer kan du velge
        faktura som betalingsløsning. Tjenesten koster kr. 32,90 per minutt.
      </p>

      <b>Betaling via telefonregning</b>
      <p>
        Når du ringer veileder på annonserte 829xxxxx nummere kan du velge
        betaling via telefonregning. Tjenesten koster da 32,90 per minutt.
      </p>

      <h4>2. Betaling</h4>

      <b>Kortbetaling</b>
      <p>
        Varen/tjenesten gjøres tilgjengelig umiddelbart etter betaling, ved at
        nummeret kjøpet gjelder for, sendes direkte til vårt telefonisystem så
        fort betalingen er godkjent. Tjenesten skal være tilgjengelig for deg
        som kunde senest påfølgende virkedag.
      </p>

      <p>
        Kjøpet er bindende når bestilling/betaling er registrert hos oss.
      </p>
      <p>
        Vi forplikter oss i utgangspunktet ikke til å oppbevare kortnummer
        utover det som er nødvendig for å sikre effektiv håndtering av
        eventuelle problemer med belastning, oppheving av reservasjon og
        kreditering.
      </p>
      <p>
        For å gjøre det enklere å handle hos oss neste gang, kan du ved betaling
        selv velge å lagre kortinformasjonen din sikkert hos oss. For å utføre
        betalingen viderebringes opplysninger til vår betalingsformidler
        Bambora. De oppbevarer informasjonen på en sikker og forsvarlig måte i
        henhold til forskriftene.
      </p>

      <b>Faktura</b>

      <p>
        Det tilkommer et fakturagebyr på kr. 49,- per faktura. Det faktureres en
        gang per uke for samtaler foregående uke. Vi har en maksgrense på kr.
        5000,- i forbruk per måned, men beløpet kan oppjusteres når vi ser at
        fakturaer blir betalt.
      </p>

      <p>
        Dersom betalingen ikke registreres innen satt forfallsdato, vil det
        tilkomme et purregebyr på 35 NOK. Ved fortsatt utelatt betaling sendes
        et inkassovarsel med nytt purregebyr. Kravet blir deretter sendt til
        inkasso og ytterligere omkostninger vil påløpe.
      </p>

      <p>
        Veiledertjenesten viderebringer opplysninger til Arvato for selve
        utfaktureringen.
      </p>

      <b>Betaling via telefonregning</b>

      <p>
        Det er Strex som håndterer betalingen på vegne av operatørene og de
        oversender informasjon til din mobiloperatør. Du vil motta SMS
        kvittering for hvert kjøp. Maksbeløp for uregistrerte kunder er kr. 3000
        per måned og 500 kroner per kjøp/engangstrekk. En samtale kan bestå av
        flere kjøp, så du trenger ikke avslutte etter passering av 500 kroner.
        Du kan ved registrering hos Strex.no bruke inntil 5000 kroner per måned
        og 1000 kroner per kjøp.
      </p>

      <h4>3. Angrerett kortbetaling:</h4>

      <p>
        Angrerettloven gir deg rett til å angre ditt kjøp. Angreretten gjelder
        for varer og for enkelte tjenester (se unntak i siste avsnitt).
        Angreretten forutsetter at du senest innen 14 dager etter at du mottar
        leveransen gir Selgeren av leveransen melding om dette (angrefrist).
        Angreretten gjelder kun ubenyttede ringekort. Ta kontakt og informer om
        at du ønsker å benytte angreretten. Vi kan kontaktes på telefon
        22004600.
      </p>
      <p>Restbeløp på allerede benyttede ringekort refunderes ikke.</p>
      Angrerettskjema kan
      <a class="tf-underline" href="angrerett.pdf" target="_blank"
        >lastes ned her</a
      >

      <h4>4. Retningslinjer for personvern</h4>
      <p>
        Ved å akseptere våre salgsbetingelser gir du også ditt samtykke til å
        oppbevare og behandle dine personopplysninger.
      </p>
      <p>
        Vennligst les våre
        <router-link class="tf-underline" to="/privacy"
          >retningslinjer om personvern her</router-link
        >
      </p>

      <h4>5. Endring i vilkårene</h4>
      <p>
        Vi forbeholder oss retten til å endre nåværende vilkår, herunder som
        følge av endringer i lovgivningen.
      </p>

      <h4>6. Force majeure</h4>
      <p>
        Begrepet force majeure defineres i Norsk Lov. Dersom force majeure
        hindrer eller gjør oppfyllelse vesentlig vanskeligere enn normalt, gir
        ikke mangel eller forsinkelse som skyldes force majeure-situasjonen rett
        til misligholdsbeføyelser.
      </p>

      <p>
        Er vi forhindret fra å levere eller foreta pliktig omlevering, eller
        blir en slik leveringsplikt urimelig tyngende som følge av
        arbeidskonflikt eller enhver annen omstendighet når partene ikke kan
        råde over den, så som ved brann, krig, mobilisering eller uforutsette
        militære innkallelser av tilsvarende omfang, rekvisisjon, beslag,
        valuta- restriksjoner, opprør og oppløp, samt mangler ved eller
        forsinkelser av leveranser fra underleverandører eller produsenter som
        følge av slike omstendigheter som omhandles i dette punkt, er Selgere
        fritatt for alt annet ansvar enn i vanlige reklamasjonstilfeller med
        tilhørende prosedyrer.
      </p>
      <p>
        Den part som påberoper seg force majeure, skal underrette den annen part
        om den aktuelle force majeure-begivenhet og om når ytelsen antas å kunne
        gjennomføres.
      </p>

      <h4>7. Tvister og lovvalg</h4>
      <p>
        Tvister i anledning betingelsene og tilhørende bestemmelser, samt
        tvister som ellers angår handel på veiledningstjenester.no følger norsk
        rett og hører inn under de ordinære tvisteløsningsorganer og domstoler,
        med Oslo tingrett som verneting ved eventuell behandling for tingrett.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created: function() {},
  name: "terms",
  methods: {},
  computed: {}
};
</script>
