<template>
  <div class="tf-background" ref="localTop">
    <div class="tf-width">
      <div
        class="uk-width-2-3@s uk-width-3-5@xl uk-border-rounded uk-align-center uk-card uk-card-default tf-quickpay-padding uk-box-shadow-large"
        v-if="!this.has_completed_purchase && !this.wants_to_register"
      >
        <div class="uk-text-center">
          <h1 class="uk-text-lighter">Betaling</h1>
          <br />
          <p class="tf-large-paragraph">Tast inn nummeret du vil ringe fra</p>
          <div class="uk-margin-remove-top uk-align-center uk-width-1-2@s">
            <vue-tel-input
              v-model="number_input"
              v-bind="telProps"
              @onInput="onNumberInput"
            />
          </div>
        </div>
        <br />

        <div v-bind:class="{ invalid: !this.number_validated }">
          <div class="uk-width-5-6@m uk-text-center uk-align-center">
            <hr class="tf-divider" />
          </div>
          <div class="tf-margin-large">
            <div class="uk-text-center">
              <p class="tf-large-paragraph">Velg beløp</p>
              <br />
              <span class="tf-label-text">Hurtigvalg</span>
            </div>
            <div
              v-bind:class="{ valid: this.number_validated }"
              class="uk-margin radio-toolbar"
            >
              <div
                class="uk-grid-small uk-child-width-expand uk-button-group"
                uk-grid
              >
                <div
                  v-for="(price, index) in prices"
                  :key="index"
                  class="uk-width-1-4@l uk-width-1-2@s"
                >
                  <input
                    type="radio"
                    v-model="amount"
                    :id="index"
                    :value="price.amount"
                  />
                  <label :for="index">{{ price.amount }} kr</label>
                </div>
              </div>
            </div>

            <div class="uk-margin-remove-bottom uk-align-center uk-width-1-2@s">
              <br />
              <div class="tf-label-text">eller velg beløpet selv</div>
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="amount"
                placeholder="Beløp"
                :disabled="!this.number_validated"
              />
              <br />
              <br />
              <span class="tf-mva-info">
                Samtalepris er kr 29,90 per minutt. Alle priser er inkl. MVA.
              </span>
            </div>
          </div>

          <div class="uk-width-5-6@m uk-text-center uk-align-center">
            <hr class="tf-divider" />
          </div>
          <div class="tf-margin-large uk-text-center">
            <p class="tf-large-paragraph">Lagre betalingsinformasjon</p>
            <div
              class="tf-info-background tf-info-text uk-width-3-5@l uk-align-center uk-border-rounded uk-padding-small"
            >
              <span uk-icon="icon: credit-card; ratio:2" />

              <p>
                Ved å lagre betalingsinformasjonen vil du neste gang kunne fylle
                på kontoen din underveis i samtalen.
              </p>
            </div>
            <div class="uk-margin">
              <button
                class="uk-width-2-3@l uk-width-1-2@xl uk-button uk-button-primary uk-border-pill"
                @click="pay(1)"
                :disabled="!this.number_validated"
              >
                Lagre og fortsett til betaling
                <span
                  class="tf-button-chevron uk-align-right uk-margin-remove"
                  uk-icon="icon: chevron-right; ratio:1.3"
                />
              </button>
            </div>
            <div class="uk-margin">
              <button
                class="uk-width-2-3@l uk-width-1-2@xl uk-button uk-button-default uk-border-pill"
                @click="pay(0)"
                :disabled="!this.number_validated"
              >
                Betal uten å lagre kort
                <span
                  class="tf-button-chevron uk-align-right uk-margin-remove"
                  uk-icon="icon: chevron-right; ratio:1.3"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="uk-padding-large uk-align-center uk-width-1-2@m uk-card uk-card-body uk-card-default uk-border-rounded uk-box-shadow-medium"
        v-if="this.has_completed_purchase && !this.wants_to_register"
      >
        <div
          class="tf-info-background uk-width-4-5@l uk-text-center uk-align-center uk-border-rounded uk-padding"
        >
          <h1 class="uk-text-lighter">
            Betaling vellykket
            <span uk-icon="icon: check; ratio:3" />
          </h1>

          <p class="tf-large-paragraph">
            Du har fylt på {{ Number(this.amount) | toCurrency }} for å bruke
            tjenesten.
            <br />
            <br />
            Tilgjengelig saldo er nå
            {{ this.currentBalance() | toCurrency }},-
            <br />
            <br />Du kan nå ringe din veileder.
          </p>
        </div>
        <br />
        <div class="uk-margin">
          <br />
          <br />
          <div class="uk-text-center">
            <button
              class="uk-button uk-button-link tf-underline"
              @click="toRegistration(1)"
            >
              Registrer Bruker nå
            </button>
            og se saldo og forbuk på et sted
          </div>
        </div>
      </div>

      <verification-form
        ref="verification"
        v-if="this.wants_to_register && !this.verified"
        :number="this.full_number"
        @verify:user="onVerify"
      ></verification-form>

      <registration-form
        ref="registration"
        v-if="this.verified && this.has_completed_purchase"
        :number="this.full_number"
        @register:user="onRegister"
      ></registration-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { settings } from "@/settings";
import PhoneNumber from "awesome-phonenumber";
import {
  BAMBORA_QUICK_PAYMENT_REQUEST,
  BAMBORA_UPDATE_BALANCE,
} from "@/store/actions/bambora";
import { USER_NAME_LOOKUP } from "../../store/actions/user";
import * as Bambora from "@bambora/checkout-sdk-web";

export default {
  data() {
    return {
      prices: [
        { amount: 300 },
        { amount: 500 },
        { amount: 1000 },
        { amount: 2000 },
      ],
      found: 0,
      full_number: "",
      number_input: "",
      code: "",
      amount: "500",
      has_completed_purchase: false,
      verified: false,
      wants_to_register: false,
      telProps: {
        placeholder: "",
        required: true,
        preferredCountries: ["NO", "SE", "DK"],
      },
      referrer: "",
      return_to: "",
      number_validated: true,
    };
  },
  created: function () {},
  name: "quickpay",
  methods: {
    currentBalance() {
      let balance = Number(this.getCurrentBalance);
      let amount = Number(this.amount);
      return Number(balance + amount);
    },
    toRegistration(register) {
      if (register) {
        this.wants_to_register = true;
      } else {
        this.$router.push("/");
      }
    },
    toReferrableSite() {
      window.location = this.referrer;
    },
    onNumberInput({ number, isValid }) {
      this.full_number = number.e164;
      isValid
        ? (this.number_validated = true)
        : (this.number_validated = false);
    },
    onVerify(success) {
      if (success) {
        this.verified = true;
      } else {
        this.verified = false;
      }
    },
    onRegister(success) {
      if (success) {
        this.wants_to_register = false;
        this.$router.push("/profile");
      }
    },
    validateAmount(amount) {
      if (!isNaN(parseFloat(amount)) && isFinite(amount) && amount >= 10) {
        return true;
      }
      return false;
    },
    pay(should_store_token) {
      if (this.validateAmount(this.amount)) {
        var checkout = new Bambora.ModalCheckout(null);
        this.$store
          .dispatch(BAMBORA_QUICK_PAYMENT_REQUEST, {
            amount: this.amount,
            number: this.full_number,
            should_store_token: should_store_token,
            common_id: settings.COMMON_ID,
          })
          .then(() => {
            checkout
              .initialize(this.getToken)
              .then(() => {
                checkout.show();
              })
              .then(() => {
                checkout.on(Bambora.Event.Close, this.completePurchase);
              });
          })
          .catch((error) => {
            this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
              message:
                "Noe gikk galt ved betaling. Venligst kontakt oss på " +
                settings.SUPPORT_EMAIL +
                " dersom problemet vedvarer",
            });
          });
      } else {
        this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
          message:
            "Beløp må være et positivt heltall pålydende 200 kr eller mer",
        });
      }
    },
    completePurchase() {
      this.$scrollTo(this.$refs["localTop"], 500, {
        offset: -200,
        x: false,
        y: true,
      });
      this.$store
        .dispatch(BAMBORA_UPDATE_BALANCE, Number(this.amount))
        .then(() => {
          if (this.isAuthenticated) {
            this.$router.push("/profile");
          }
          this.has_completed_purchase = true;
          if (this.getIsReferrableSite()) {
            this.$store.dispatch("user/USER_DISPLAY_RIBBON", {
              message: "For å gå tilbake til " + this.return_to,
              link: this.referrer,
              link_text: "klikk her",
            });
          }
        });
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (
        match != null &&
        match.length > 2 &&
        typeof match[2] === "string" &&
        match[2].length > 0
      ) {
        return match[2];
      } else {
        return null;
      }
    },
    getIsReferrableSite() {
      for (const site of settings.REFERRER_SITES) {
        if (this.getHostName(this.referrer) == site) {
          this.return_to = site;
          return true;
        }
      }
      return false;
    },
    testNameLookup() {
      let numbers = [];
      console.log("total: " + numbers.length);
      for (let number of numbers) {
        this.$store
          .dispatch(USER_NAME_LOOKUP, Number(number))
          .then(() => {
            let res = this.getNameLookup;
            if (res.contacts) {
              let contacts = res.contacts[0];
              console.log(
                contacts.name + " " + contacts.geography.address.addressString
              );
              this.found++;
            }
          })
          .then(() => {
            console.log(this.found);
          });
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("bambora", ["getCurrentBalance"]),
    ...mapGetters("user", [
      "getProfile",
      "isVerified",
      "isAdmin",
      "isSuperAdmin",
      "getTransactionCredits",
      "getTransactionDebits",
    ]),
    ...mapGetters(["getToken"]),
  },
  mounted() {
    this.referrer = document.referrer;
    //this.testNameLookup();
  },
  beforeMount() {
    if (this.isAuthenticated) {
      if (
        this.getProfile.main_number &&
        new PhoneNumber(this.getProfile.main_number).isValid()
      ) {
        this.full_number = new PhoneNumber(
          this.getProfile.main_number
        ).getNumber("e164");
        this.number_input = new PhoneNumber(
          this.getProfile.main_number
        ).getNumber("significant");
        this.number_validated = true;
      }
    }
    if (this.$route.params.number) {
      if (new PhoneNumber(this.$route.params.number).isValid()) {
        this.full_number = new PhoneNumber(this.$route.params.number).getNumber(
          "e164"
        );
        this.number_input = new PhoneNumber(
          this.$route.params.number
        ).getNumber("significant");
        this.number_validated = true;
      }
    }
  },
};
</script>
