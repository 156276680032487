<template>
  <div class="tf-background">
    <div class="tf-width">
      <div
        class="uk-border-rounded uk-align-center uk-width-1-3@l uk-width-1-2@m uk-width-2-3@s uk-card uk-card-body uk-card-default uk-box-shadow-medium"
      >
        <h2 class="uk-text-lighter">Glemt passord</h2>
        <br />
        <div class="uk-form" autocomplete="off" method="post">
          <span>E-postaddresse</span>
          <input
            type="email"
            class="uk-input uk-border-rounded"
            v-model="email"
            required
          />
          <p>
            Du vil motta en e-post med lenke for å opprette et nytt passord.
          </p>
          <button
            type="submit"
            class="uk-text-capitalize uk-margin uk-align-center uk-button uk-button-primary uk-border-pill"
            @click="requestResetPassword()"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { settings } from "@/settings";
export default {
  data() {
    return {
      email: null,
      has_error: false
    };
  },
  methods: {
    requestResetPassword() {
      if (!this.validEmail(this.email)) {
        this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
          message: "Gyldig e-post addresse kreves."
        });
        return;
      }

      const { email } = this;
      this.$store
        .dispatch("auth/AUTH_FORGOT_PASSWORD", {
          email
        })
        .then(() => {
          this.$store.dispatch("user/USER_DISPLAY_RIBBON", {
            message:
              "Vi har sendt deg en e-post med instruksjoner om hvordan du endrer passordet ditt"
          });
        })
        .catch(error => {
          this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
            message:
              "Noe gikk galt under utsending av e-post. Venligst ta kontakt med oss på " +
              settings.SUPPORT_EMAIL
          });
        });
    },
    validEmail: function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
};
</script>
