<template>
  <div class="uk-padding-small">
    <div
      class="
        tf-terms-text
        uk-margin-large-bottom
        uk-margin-large-top
        uk-width-2-3@m
        uk-width-1-3@xl
        uk-width-1-2@l
        uk-align-center
      "
    >
    <h1 class="uk-text-lighter">Kontakt oss</h1>
      <h4>Tlf: <a href="tel:46004444">4600 4444</a></h4>
      <p>Epost: <a href="mailto:kundeservice@veiledertjenesten.no">kundeservice@veiledertjenesten.no</a></p>
      <p>SMS: send kodeord VEILEDER + tekst til 26444</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created: function () {},
  name: "contact",
  methods: {},
  computed: {},
};
</script>
