<template>
  <div class="tf-page-container uk-child-width-expand uk-grid-collapse" uk-grid>
    <div class="uk-visible@m uk-width-2-3@m">
      <div
        class="uk-width-1-1@s tf-background-image uk-background-cover uk-height-1-1"
        uk-img
      >
        <h1 class="uk-text-lighter tf-width tf-frontpage-text">
          Snakk med en veileder idag, trygt og anonymt
        </h1>
      </div>
    </div>

    <div class="uk-width-1-3@m uk-light tf-green-background">
      <div class="uk-width-2-3@s uk-width-1-1@m tf-frontpage-box">
        <div class="uk-align-center">
          <h1 class="uk-text-lighter uk-text-center">Kjøp av ringekreditt</h1>
          <h4 class="uk-text-lighter">
            Kjøpte ringeminutter kan deles opp i flere samtaler, og benyttes
            fritt til alle våre veiledere på deres annonserte 21xxxxxx nummer.
          </h4>
<!--           <div v-for="price in calcPrices()" :key="price.id">
            <h4>{{ price.supported_payment_option.description }} - {{ price.price_per_minute }}</h4>
          </div> -->
        </div>

        <div
          class="tf-home-maxwidth uk-margin uk-border-rounded uk-align-center uk-width-4-5@s"
        >
          <span class="uk-text-left">Nummeret du vil ringe fra</span>
          <vue-tel-input
            v-model="main_number"
            v-bind="telProps"
            @onInput="onNumberInput"
          />
        </div>

        <div class="uk-align-center uk-text-center">
          <button
            class="tf-beige-background uk-button uk-button-primary uk-border-pill"
            @click="toQuickpay()"
            :disabled="!number_validated"
          >
            Fortsett til betaling
            <span
              class="tf-button-chevron uk-float-right"
              uk-icon="icon: chevron-right; ratio:1.3"
            />
          </button>
        </div>
        <span class="uk-text-center uk-align-center tf-14px-text">
          Betalingstjeneste levert av
          <img
            class="tf-bambora-logo"
            id="tf-bambora"
            src="~@/assets/bambora.svg"
            uk-svg
          />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { settings } from "@/settings";
export default {
  data() {
    return {
      main_number: "",
      full_number: "",
      number_validated: false,
      telProps: {
        placeholder: "",
        required: true,
        preferredCountries: ["NO", "SE", "DK"],
      },
    };
  },
  created: function () {},
  name: "home",
  methods: {
    onNumberInput({ number, isValid }) {
      this.full_number = number.e164;
      isValid
        ? (this.number_validated = true)
        : (this.number_validated = false);
    },
    toQuickpay() {
      this.$router.push({
        name: "quickpay",
        params: { number: this.full_number },
      });
    },
    calcPrices() {
      return this.getPrices.filter(el => !el.is_vip);
    },
  },
  computed: {
    ...mapGetters("billing", ["getPrices"]),
  },
};
</script>
