<template>
  <div class="tf-background" ref="localTop">
    <div class="tf-width">
      <div
        class="
          uk-width-2-3@s
          uk-width-3-5@xl
          uk-border-rounded
          uk-align-center
          uk-card
          uk-card-default
          tf-quickpay-padding
          uk-box-shadow-large
        "
        v-if="!this.number_entered"
      >
        <div class="uk-text-center">
          <h1 class="uk-text-center uk-text-lighter">
            Registrere faktura-konto
          </h1>

          <br />
          <p class="tf-large-paragraph">Tast inn nummeret ditt</p>
          <div class="uk-margin-remove-top uk-align-center uk-width-1-2@s">
            <vue-tel-input
              v-model="number"
              v-bind="telProps"
              @onInput="onNumberInput"
            />
          </div>
          <br />
          <button
            class="
              uk-align-center
              uk-margin
              uk-button
              uk-button-primary
              uk-border-pill
            "
            @click="toValidation()"
            :disabled="!this.number_validated"
          >
            Til validering
            <span
              class="tf-button-chevron uk-float-right"
              uk-icon="icon: chevron-right; ratio:1.3"
            />
          </button>
        </div>
        <br />
      </div>
      <verification-form
        ref="verification"
        v-if="!this.verified && this.number_entered"
        :number="this.number"
        @verify:user="onVerify"
      ></verification-form>

      <invoice-registration-form
        ref="registration"
        v-if="this.verified"
        :number="this.number"
        @register:user="onRegister"
      ></invoice-registration-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PhoneNumber from "awesome-phonenumber";

export default {
  data() {
    return {
      number: "",
      code: "",
      verified: false,
      number_validated: false,
      number_entered: false,
      telProps: {
        placeholder: "",
        required: true,
        preferredCountries: ["NO", "SE", "DK"],
      },
    };
  },
  created: function () {},
  name: "register-email",
  methods: {
    onVerify(success) {
      if (success) {
        this.verified = true;
      } else {
        this.verified = false;
      }
    },
    onRegister(success) {
      if (success) {
        this.wants_to_register = false;
        this.$store.dispatch("user/USER_DISPLAY_RIBBON", {
          message: "Vi har sendt deg en kode per SMS",
        });
        this.$router.push("/");
      }
    },
    toValidation() {
      this.number_validated = true;
      this.number_entered = true;
    },
    onNumberInput({ number, isValid }) {
      this.number = number.e164;
      isValid
        ? (this.number_validated = true)
        : (this.number_validated = false);
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("user", ["getProfile", "isVerified"]),
  },
  mounted() {
    const num = new PhoneNumber(this.$route.params.number);
    if (num.isValid()) {
      this.number = num.a.number.e164;
      this.number_entered = true;
      this.number_validated = true;
    }
  },
  beforeMount() {},
};
</script>
