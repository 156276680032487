import {
  BILLING_PRICE_REQUEST,
} from "@/store/actions/billing";
import { apiCall, api_routes } from "@/utils/api";
import { settings } from "@/settings";

const state = {
  prices: {},
  status: "",
};

const getters = {
  getPrices: state => state.prices
};

const actions = {
  [BILLING_PRICE_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.billing.getprices + "?common_id=" + settings.COMMON_ID
      })
        .then(resp => {
          commit("get_prices_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_prices_error", err);
          reject(err);
        });
    });
  },
};
const mutations = {
  get_prices_success(state, resp) {
    state.status = "success";
    state.prices = resp.data;
  },
  get_prices_error(state) {
    state.status = "error";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
