export const USER_REQUEST = "USER_REQUEST";
export const USER_VERIFY = "USER_VERIFY";
export const USER_SEND_VERIFICATION_CODE = "USER_SEND_VERIFICATION_CODE";
export const USER_NAME_LOOKUP = "USER_NAME_LOOKUP";
export const USER_GET_TRANSACTION_CREDITS = "USER_GET_TRANSACTION_CREDITS";
export const USER_GET_TRANSACTION_DEBITS = "USER_GET_TRANSACTION_DEBITS";
export const USER_SEND_VEILEDER_CONTACT_EMAIL =
  "USER_SEND_VEILEDER_CONTACT_EMAIL";
export const USER_DISPLAY_RIBBON = "USER_DISPLAY_RIBBON";
export const USER_DISPLAY_RIBBON_ERROR = "USER_DISPLAY_RIBBON_ERROR";
export const USER_RESET_RIBBONS = "USER_RESET_RIBBONS";
