<template>
  <div class="tf-grid-wrapper uk-grid-collapse" uk-grid>
    <header>
      <nav class="tf-width uk-navbar-container uk-navbar-transparent" uk-navbar>
        <div class="uk-navbar-left">
          <ul class="uk-navbar-nav">
            <li>
              <router-link class="uk-logo uk-navbar-item" to="/">
                <img class="tf-logo" src="~@/assets/logo2.svg" uk-svg />
              </router-link>
            </li>
          </ul>
        </div>
        <div class="uk-navbar-right">
          <ul class="uk-navbar-nav uk-visible@l">
            <li>
              <router-link class="uk-text-uppercase" to="/quickpay">
                <span id="tf-bli-veileder-menu-item">Betaling</span>
              </router-link>
            </li>
            <li>
              <router-link class="uk-text-uppercase" to="/om-oss"
                >Om tjenesten</router-link
              >
            </li>
            <li>
              <router-link class="uk-text-uppercase" to="/vilkar">{{
                $t("terms_and_conditions")
              }}</router-link>
            </li>
            <li v-if="isAuthenticated">
              <router-link class="uk-text-uppercase" to="/profile">{{
                $t("profile")
              }}</router-link>
            </li>
            <li class>
              <router-link class="uk-text-uppercase" to="/agent">
                {{ $t("become_agent") }}
              </router-link>
            </li>
            <li class="uk-nav-divider"></li>
            <li v-if="isAuthenticated">
              <a @click="logout()">
                <span
                  class="uk-margin-small-right"
                  uk-icon="icon: sign-out"
                ></span>
                {{ $t("logout") }}
              </a>
            </li>
            <li v-if="!isAuthenticated">
              <router-link to="/login">
                <span
                  class="uk-margin-small-right"
                  uk-icon="icon: sign-in"
                ></span>
                {{ $t("login") }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="uk-navbar-right uk-hidden@l">
          <a
            href="#"
            class="uk-navbar-toggle"
            uk-navbar-toggle-icon
            uk-toggle="target: #sidenav"
          ></a>
        </div>
      </nav>

      <div
        id="sidenav"
        uk-offcanvas="overlay: true; mode: push; flip: true"
        class="uk-offcanvas"
        ref="offcanvasMenu"
        @click="onClick()"
      >
        >
        <div class="uk-offcanvas-bar tf-dark-gray-background">
          <ul class="uk-nav">
            <li>
              <router-link class="uk-text-uppercase" to="/quickpay"
                >Betaling</router-link
              >
            </li>
            <li>
              <router-link class="uk-text-uppercase" to="/om-oss"
                >Om tjenesten</router-link
              >
            </li>
            <li>
              <router-link class="uk-text-uppercase" to="/vilkar">{{
                $t("terms_and_conditions")
              }}</router-link>
            </li>

            <li v-if="isAuthenticated">
              <router-link class="uk-text-uppercase" to="/profile">{{
                $t("profile")
              }}</router-link>
            </li>
            <li>
              <router-link class="uk-text-uppercase" to="/agent">
                {{ $t("become_agent") }}
              </router-link>
            </li>
            <li class="uk-nav-divider"></li>
            <li v-if="isAuthenticated">
              <a @click="logout()">
                <span
                  class="uk-margin-small-right"
                  uk-icon="icon: sign-out"
                ></span>
                {{ $t("logout") }}
              </a>
            </li>
            <li v-if="!isAuthenticated">
              <a href="/login">
                <span
                  class="uk-margin-small-right"
                  uk-icon="icon: sign-out"
                ></span>
                {{ $t("login") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>

    <main class="tf-margin-before-footer" ref="main">
      <div
        v-if="getRibbonMessage.message"
        class="uk-alert uk-alert-success"
        uk-alert
      >
        <div class="tf-width uk-text-center">
          <a
            @click="onCloseRibbonMessage()"
            class="uk-alert-close"
            uk-close
          ></a>
          <span class="tf-alert-text">
            {{ getRibbonMessage.message }}
            <a :href="getRibbonMessage.link">{{
              getRibbonMessage.link_text
            }}</a>
          </span>
        </div>
      </div>
      <div
        v-if="getRibbonError.message"
        class="uk-alert uk-alert-danger uk-margin-remove-top"
        uk-alert
      >
        <div class="tf-width uk-text-center">
          <a @click="onCloseRibbonError()" class="uk-alert-close" uk-close></a>
          <span class="tf-alert-text">
            {{ getRibbonError.message }}
            <a :href="getRibbonError.link">{{ getRibbonError.link_text }}</a>
          </span>
        </div>
      </div>
      <router-view></router-view>
    </main>

    <footer>
      <div class="tf-width">
        <div
          class="uk-visible@m uk-grid-collapse uk-child-width-1-3@m tf-footer"
          uk-grid
        >
          <div>
            <div>
              <img class="tf-logo" src="~@/assets/logo2.svg" uk-svg />
            </div>
            <div class="uk-margin tf-14px-text">
              <router-link class="uk-text-uppercase tf-underline" to="/personvern"
                >Personvernerklæring</router-link
              >
              <router-link
                class="uk-text-uppercase tf-underline uk-margin-left"
                to="/vilkar"
                >Vilkår</router-link
              >
              <router-link class="uk-text-uppercase uk-margin-left tf-underline" to="/kontakt-oss"
                >Kontakt oss</router-link
              >
            </div>
          </div>
          <div class="tf-footer-payment">
            <div class="uk-grid-small uk-flex-center" uk-grid>
              <div>
                <img
                  class="tf-paymentlogo tf-width-75 tf-margin-top-15"
                  id="tf-visa"
                  src="~@/assets/visa.svg"
                  uk-svg
                />
              </div>
              <div>
                <img
                  class="tf-paymentlogo tf-width-75"
                  id="tf-mc"
                  src="~@/assets/mc.svg"
                  uk-svg
                />
              </div>
              <div>
                <img
                  class="tf-paymentlogo tf-width-75"
                  id="tf-ms"
                  src="~@/assets/ms.svg"
                  uk-svg
                />
              </div>
            </div>
          </div>
          <div class="tf-14px-text uk-text-center">
            <div>Tilknyttede partnere:</div>
            <div class="uk-margin-small">
              <a href="https://minkrystallkule.no" target="_blank">
                <img
                  class="tf-mklogo"
                  src="~@/assets/logo_whiteminkristallkule.png"
                />
              </a>
            </div>
            <div class="uk-margin-small">
              <a href="https://tarot.no" target="_blank">
                <img class="tf-mklogo" src="~@/assets/tarotno.svg" />
              </a>
            </div>
            <div>
              <a href="https://klarsynte.no" target="_blank">
                <img class="tf-mklogo" src="~@/assets/klarsynte.svg" />
              </a>
            </div>
          </div>
        </div>
        <div
          class="uk-hidden@m uk-grid-collapse uk-child-width-1-3@m uk-padding"
          uk-grid
        >
          <div class="uk-align-center uk-text-center">
            <div>
              <img class="tf-logo" src="~@/assets/logo2.svg" uk-svg />
            </div>
            <div class="uk-margin tf-14px-text">
              <router-link class="uk-text-uppercase tf-underline" to="/personvern"
                >Personvernerklæring</router-link
              >
              <br />
              <br />
              <router-link class="uk-text-uppercase tf-underline" to="/vilkar"
                >Vilkår</router-link
              >
              <router-link class="uk-text-uppercase uk-margin-left tf-underline" to="/kontakt-oss"
                >Kontakt oss</router-link
              >
            </div>
          </div>
          <div class="uk-align-center uk-width-2-5@s">
            <div
              class="uk-child-width-1-3 uk-text-center uk-grid-small uk-grid-collapse"
              uk-grid
            >
              <div>
                <img
                  class="tf-paymentlogo tf-width-75 tf-margin-top-15"
                  id="tf-visa"
                  src="~@/assets/visa.svg"
                  uk-svg
                />
              </div>
              <div>
                <img
                  class="tf-paymentlogo tf-width-75"
                  id="tf-mc"
                  src="~@/assets/mc.svg"
                  uk-svg
                />
              </div>
              <div>
                <img
                  class="tf-paymentlogo tf-width-75"
                  id="tf-ms"
                  src="~@/assets/ms.svg"
                  uk-svg
                />
              </div>
            </div>
          </div>
          <div class="tf-14px-text uk-text-center">
            <div>Tilknyttede partnere:</div>
            <div class="uk-margin-small">
              <a href="https://minkrystallkule.no" target="_blank">
                <img
                  class="tf-mklogo"
                  src="~@/assets/logo_whiteminkristallkule.png"
                />
              </a>
            </div>
            <div class="uk-margin-small">
              <a href="https://tarot.no" target="_blank">
                <img class="tf-mklogo" src="~@/assets/tarotno.svg" />
              </a>
            </div>
            <div>
              <a href="https://klarsynte.no" target="_blank">
                <img class="tf-mklogo" src="~@/assets/klarsynte.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="tf-14px-text uk-text-center uk-margin">
          <p>©{{ new Date().getFullYear() }} {{ appName }}</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { settings } from "@/settings";
export default {
  name: "app",
  methods: {
    onCloseRibbonMessage() {
      this.$store.dispatch("user/USER_DISPLAY_RIBBON", {
        message: "",
        link: "",
        link_text: "",
      });
    },
    onCloseRibbonError() {
      this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
        message: "",
        link: "",
        link_text: "",
      });
    },
    logout() {
      this.$store.dispatch("auth/AUTH_LOGOUT").then((resp) => {
        this.$router.push("/");
        window.location.reload();
      });
    },
    onClick() {
      //eslint-disable-next-line
      UIkit.offcanvas(this.$refs.offcanvasMenu).hide();
    },
  },
  watch: {
    getRibbonMessage: {
      deep: true,
      handler(newValue) {
        if (newValue.message && newValue.message !== "") {
          this.$scrollTo(this.$refs["main"], 500, {
            offset: -200,
            x: false,
            y: true,
          });
        }
      },
    },
    getRibbonError: {
      deep: true,
      handler(newValue) {
        if (newValue.message && newValue.message !== "") {
          this.$scrollTo(this.$refs["main"], 500, {
            offset: -200,
            x: false,
            y: true,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("user", ["getRibbonMessage", "getRibbonError", "isVerified"]),
    ...mapGetters("billing", ["getPrices"]),

    appName() {
      return settings.APP_NAME;
    },
    support_email() {
      return settings.SUPPORT_EMAIL;
    },
  },
  beforeMount() {
    if (this.isAuthenticated) {
      this.$store.dispatch("user/USER_REQUEST");
    }
    //this.$store.dispatch("billing/BILLING_PRICE_REQUEST")
  },
};
</script>
