<template>
  <div class="tf-background">
    <div class="tf-width">
      <div
        class="uk-border-rounded uk-align-center uk-width-1-3@l uk-width-2-3@s uk-width-1-2@m uk-card uk-card-body uk-card-default uk-box-shadow-medium"
      >
        <div class="uk-form" method="post">
          <h2 class="uk-text-lighter">Opprett nytt passord</h2>
          <div class="uk-margin uk-form-group">
            <label class="uk-form-label">E-postaddresse</label>
            <input
              type="email"
              id="email"
              class="uk-input uk-border-rounded"
              v-model="email"
              readonly
              disabled
            />
          </div>

          <div class="uk-margin uk-form-group">
            <label class="uk-form-label">Nytt passord (minimum 8 tegn)</label>
            <input
              type="password"
              id="password"
              class="uk-input uk-border-rounded"
              v-model="password"
              required
            />
          </div>

          <div class="uk-margin uk-form-group">
            <label class="uk-form-label">Bekreft nytt passord</label>
            <input
              type="password"
              id="password_confirmation"
              class="uk-input uk-border-rounded"
              v-model="password_confirmation"
              required
            />
          </div>
          <button
            type="submit"
            class="uk-text-capitalize uk-align-center uk-margin-remove-bottom uk-button uk-button-primary uk-border-pill"
            @click="resetPassword()"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { settings } from "@/settings";
export default {
  data() {
    return {
      email: this.$route.params.email,
      password: null,
      password_confirmation: null,
      has_error: false
    };
  },
  methods: {
    resetPassword() {
      this.$store
        .dispatch("auth/AUTH_PASSWORD_RESET", {
          email: this.$route.params.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.$route.params.token
        })
        .then(() => {
          this.$store.dispatch("user/USER_DISPLAY_RIBBON", {
            message:
              "Endring av passord fullført. Du kan nå forsøke å logge deg inn."
          });
        })
        .catch(error => {
          this.$store.dispatch("user/USER_DISPLAY_RIBBON_ERROR", {
            message:
              "Noe gikk galt under tilbakestilling av passord. Venligst ta kontakt med oss på " +
              settings.SUPPORT_EMAIL
          });
        });
    }
  }
};
</script>
